import { Application, ApplicationTranslations, ImportedApplicationTranslations, LanguageTranslation } from '../../../API/application';
import { prepareForCompare } from '../../../Utils';
import { ApplicationErrors, ApplicationWarnings } from '../../ImportPopup/types';
import { ApplicationOption } from './types';
import DOMPurify from 'dompurify';

export const validateImportedData = (importedData: ImportedApplicationTranslations, applicationTranslations: ApplicationTranslations, userApplications: ApplicationOption[], applications: Application[]): { errors: ApplicationErrors[], warnings: ApplicationWarnings[] } => {
    const errors: ApplicationErrors[] = [];
    const warnings: ApplicationWarnings[] = [];
    const errorsForApplication: string[] = [];
    const warningsForApplication: string[] = [];
    
    const application = applications.find(app => app.client_id === importedData.client_id) || {} as Application;

    // Check if data belongs to an application the the user has access to
    if (!userApplications.some(app => app.id === importedData.client_id)) {
        errorsForApplication.push(`You are not allowed to import data for application ${application.name}`);
    }
    
    let hasChanges = false;

    importedData.application_texts.forEach(imTrans => {
        const lanTrans = applicationTranslations.application_texts.find(appTrans => appTrans.language === imTrans.language) || {} as LanguageTranslation;

        if (prepareForCompare(DOMPurify.sanitize(lanTrans.name || '')) !== prepareForCompare(imTrans.name || '') || prepareForCompare(DOMPurify.sanitize(lanTrans.description) || '') !== prepareForCompare(imTrans.description || '') || prepareForCompare(DOMPurify.sanitize(lanTrans.login_text) || '') !== prepareForCompare(imTrans.login_text || '') || prepareForCompare(DOMPurify.sanitize(lanTrans.signup_text) || '') !== prepareForCompare(imTrans.signup_text || '')) {
            hasChanges = true;
        }
    })

    if (!hasChanges) {
        warningsForApplication.push(`${application.name} no changes to import`);
    }

    if (applicationTranslations.application_texts.some(appTrans => !appTrans.published) && hasChanges) {
        warningsForApplication.push(`Unpublished changes for ${application.name} will be overwritten by import`);
    } 

    if (errorsForApplication.length > 0) {
        errors.push({client_name: application.name, errors: errorsForApplication});
    }

    if (warningsForApplication.length > 0) {
        warnings.push({client_name: application.name, warnings: warningsForApplication});
    }

    return { errors, warnings };
}