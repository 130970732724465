import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import settings from '../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { AppState } from '../../Store';
import { ApplicationsUserRoles, getUsersRolesPerApplication } from '../../API/roles';
import { getApplicationsForOwner } from '../../API/application';
import { getRolesEnum, RolesDisplayName } from '../../Store/Roles/types';
import { AccessRightsProps, ApplicationsPerRole } from './types';

const AccessRights: React.FC<AccessRightsProps> = ({ danfoss_identity_id, heading }) => {
    const classes = useStyles();
    const [applicationsForOwner, setApplicationsForOwner] = useState([] as string[]);
    const [applicationsPerRole, setApplicationsPerRole] = useState<ApplicationsPerRole[] | undefined>(undefined);
    const currentUser = useSelector((state: AppState) => state.user);
    const allApplications = useSelector((state: AppState) => state.applications.applications);
    const { getAccessTokenSilently } = useAuth0();

    const getApplicationsPerRole = (userRolesPerApplication: ApplicationsUserRoles, identity_id: string): ApplicationsPerRole[] => {
        const allRoles: string[] = [];
        userRolesPerApplication.user_roles.forEach(app => {
            app.users
                .filter(user => user.danfoss_identity_id === identity_id)
                .forEach(user => {
                    user.user_roles
                        .forEach(user_role => {
                            if (!allRoles.includes(user_role)) {
                                allRoles.push(user_role);
                            }
                        })
                })
        });

        return allRoles.map(role => {
            const applications = userRolesPerApplication.user_roles.filter(app => app.users.some(user => user.danfoss_identity_id === identity_id && user.user_roles.includes(role))).map(app => app.client_id);
            return { role, applications };
        })

    }

    useEffect(() => {
        const getAccessRightsForUser = async () => {
            const userId = danfoss_identity_id || currentUser.impersonatedUser?.danfoss_identity_id || currentUser.user?.identification.danfoss_identity_id;
            if (userId) {
                const accessForTokenMyDanfossAccountApi = await getAccessTokenSilently(settings.myDanfossAccountApi.accessTokenOptions);
                const accessForTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);

                const [applicationsForOwnerResponse, userRolesPerApplicationResponse] = await Promise.all([
                    getApplicationsForOwner(userId, accessForTokenMyDanfossAccountApi),
                    getUsersRolesPerApplication(userId, accessForTokenMyDanfossApi)
                ]);
                setApplicationsForOwner(applicationsForOwnerResponse);
                setApplicationsPerRole(getApplicationsPerRole(userRolesPerApplicationResponse, userId));
            }
        };

        getAccessRightsForUser();
    }, [])

    if (!((applicationsForOwner && applicationsForOwner.length > 0) || (applicationsPerRole && applicationsPerRole.length > 0))) {
        return null;
    }
    return (
        <Grid container className={classes.rolesContainer} >
            <Grid item className={classes.heading}>
                {heading}
            </Grid>
            {applicationsForOwner && applicationsForOwner.length > 0 &&
                <React.Fragment>
                    <Grid item xs={2}>
                        Application owner:
                    </Grid>
                    <Grid item xs={10}>
                        {applicationsForOwner.map(ownedApp =>
                            allApplications?.find(app => app.client_id === ownedApp)?.name || ownedApp).join(', ')}
                    </Grid>
                </React.Fragment>
            }
            {applicationsPerRole?.sort((role1, role2) => role1.role.localeCompare(role2.role)).map((role, idx) => {
                return (
                    <React.Fragment key={role.role}>
                        <Grid item xs={2}>
                            {RolesDisplayName[getRolesEnum(role.role)]}:
                        </Grid>
                        <Grid item xs={10}>
                            {role.applications
                                .map(appForRole => allApplications?.find(app => app.client_id === appForRole)?.name)
                                .filter(Boolean)
                                .join(', ')}
                        </Grid>
                    </React.Fragment>
                )
            })}
        </Grid>
    );
}

export default AccessRights;
