import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';
import { getRolesValue, Roles } from '../Store/Roles/types';
import { getUsersRolesPerApplication } from './roles';
import _ from 'lodash';

interface Response {
    ok: boolean
}

export interface ImportedApplicationTranslations {
    client_id: string,
    application_texts: LanguageTranslation[]
}

export interface ApplicationTranslations {
    application_texts: LanguageTranslation[]
}

export interface AllApplicationTranslations {
    application_text: ImportedApplicationTranslations[]
}

export interface ApplicationPublications {
    languages: string[]
}

export interface LanguageTranslation {
    language: string,
    name: string | null,
    description: string,
    login_text: string,
    signup_text: string
    published: boolean
}

export interface ApplicationWithTranslations extends Application, ApplicationTranslations {
}

export interface ApplicationData {
    application_infos: Application[];
}

export interface Application {
    client_id: string;
    name: string,
    url: string,
    allowed_callback?: string,
    app_id: string,
    app_type: string,
    dip_app_type: string,
    mobile_app_info?: MobileAppInfo[],
    uses_mfa: boolean,
    display_order?: number,
    hidden?: boolean
}

export interface ApplicationOwnersAPI {
    owners: ApplicationOwnerAPI[];
}

export interface ApplicationOwnerAPI {
    client_id: string;
    users: OwnerInfoAPI[]
}

export interface OwnerInfoAPI {
    danfoss_identity_id: string,
    email: string
}

export interface ApplicationOwner {
    client_id: string;
    name: string;
    owners: OwnerInfo[];
}
export interface OwnerInfo {
    danfoss_identity_id: string,
    email: string;
    name: string,
    phonenumber: string;
}

export interface MobileAppInfo {
    os: string,
    id?: string,
    deep_link: string
}

interface RefreshToken {
    expiration_type: string,
    idle_token_lifetime: number,
    infinite_idle_token_lifetime: boolean,
    infinite_token_lifetime: boolean,
    leeway: number,
    token_lifetime: number,
    rotation_type: string
}

interface JwtConfiguration {
    alg: string,
    lifetime_in_seconds: number,
    secret_encoded: string
}

export interface ApplicationSetupAPI {
    application_setups: ApplicationSetup[]
}

export interface ApplicationGrant {
    grant_id: string;
    api_audience: string;
    api_name: string;
    granted_scopes: string[];
}

export enum AppType {
    regular_web = 'regular_web',
    spa = 'spa',
    native = 'native',
    non_interactive = 'non_interactive',
}

export interface ApplicationSetup {
    client_id: string;
    name: string;
    app_type: AppType;
    client_metadata?: Record<string, string>;
    grants?: ApplicationGrant[];
    callbacks?: string[];
    allowed_logout_urls?: string[];
    allowed_origins?: string[];
    web_origins?: string[];
    initiate_login_uri?: string;
    refresh_token?: RefreshToken;
    grant_types?: string[];
    jwt_configuration?: JwtConfiguration;
    connections?: string[];
}

export const getApplicationTranslations = (client_id: string, language?: string): Promise<ApplicationTranslations> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');

    return fetchApiWrapper(`${settings.myDanfossAccountApi.endpoint}/applications/application-texts/${client_id}${language ? `?language=${language}` : ''}`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const getAllApplicationTranslations = (language?: string): Promise<AllApplicationTranslations> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');

    return fetchApiWrapper(`${settings.myDanfossAccountApi.endpoint}/applications/application-texts${(language) ? `?language=${language}` : ''}`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const updateApplicationTranslations = (client_id: string, translations: ApplicationTranslations, accessToken: string): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/application-texts/${client_id}`, {
        body: JSON.stringify(translations),
        headers: requestHeaders,
        method: 'PUT',
    });
}

export const publishApplicationTranslations = (client_id: string, publications: ApplicationPublications, accessToken: string): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/application-texts/publish/${client_id}`, {
        body: JSON.stringify(publications),
        headers: requestHeaders,
        method: 'PUT',
    });
}

export const deleteApplicationTranslation = (client_id: string, language: string, isDraft: boolean, accessToken: string): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/application-texts/${client_id}?language=${language}&published=${!isDraft}`, {
        headers: requestHeaders,
        method: 'DELETE',
    });
}

export const getApplicationsForOwner = (identity_id: string, accessToken: string): Promise<string[]> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)
    return fetchApiWrapper<ApplicationOwnersAPI>(`${settings.myDanfossAccountApi.endpoint}/owners?danfoss_identity_id=${identity_id}`, {
        headers: requestHeaders,
        method: 'GET',
    })
        .then(response => {
            return response.owners.filter(appOwner => appOwner.users.some(ownerInfo => ownerInfo.danfoss_identity_id === identity_id)).map(appOwner => appOwner.client_id)
        })
        .catch(response => {
            return [];
        })
}

export const getApplicationsForUserWithRole = (accessToken: string, identity_id: string, role: Roles): Promise<string[]> => {
    const roleName = getRolesValue(role);
    return getUsersRolesPerApplication(identity_id, accessToken)
        .then(response => {
            return response.user_roles
                .filter(userRole => userRole.users.some(user => user.danfoss_identity_id === identity_id && user.user_roles.includes(roleName)))
                .map(userRole => userRole.client_id)
        })
        .catch(() => []);
}

export const getApplicationsForUserWithRoles = (accessTokenMyDanfossApi: string, accessTokenMyDanfossAccountApi: string, identity_id: string, roles: Roles[]): Promise<string[]> => {
    const promises: Promise<string[]>[] = [];
    if (roles.includes(Roles.APPLICATION_OWNER)) {
        promises.push(getApplicationsForOwner(identity_id, accessTokenMyDanfossAccountApi));
    }
    promises.push(...roles.filter(role => role != Roles.APPLICATION_OWNER).map(role => (getApplicationsForUserWithRole(accessTokenMyDanfossApi, identity_id, role))));
    return Promise.allSettled(promises)
        .then(results => {
            const client_ids: string[] = [];
            results.forEach(result => {
                if (result.status === "fulfilled" && result.value) {
                    client_ids.push(...result.value);
                }
            })
            return Promise.resolve(_.uniq(client_ids));
        })
}

export const getApplicationOwners = (accessToken: string): Promise<ApplicationOwnersAPI> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossAccountApi.endpoint}/owners`, {
        headers: requestHeaders,
        method: 'GET',
    });
}

export const updateApplicationOwners = (accessToken: string, applicationOwner: ApplicationOwnerAPI): Promise<Response> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/owners`, {
        body: JSON.stringify(applicationOwner),
        headers: requestHeaders,
        method: 'PUT',
    });

}

export const getAllApplications = (accessToken: string): Promise<Application[]> => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json-patch+json; charset=utf-8');
    requestHeaders.append('Authorization', `BEARER ${accessToken}`)

    return fetchApiWrapper<ApplicationData>(`${settings.myDanfossAccountApi.endpoint}/applications`, {
        headers: requestHeaders,
        method: 'GET',
    })
        .then((result) => (result.application_infos))
}

export const getApplicationsSetup = (accessToken: string): Promise<ApplicationSetup[]> => {
    const requestHeaders = new Headers({
        'Authorization': `BEARER ${accessToken}`
    });

    return fetchApiWrapper<ApplicationSetupAPI>(`${settings.myDanfossApi.endpoint}/admin-actions/application-setups`, {
        headers: requestHeaders,
        method: 'GET',
    }).then(result => result.application_setups);
}
