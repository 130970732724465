import { takeLatest, all, put, fork, select, call } from 'redux-saga/effects';
import {
	SetApplicationsAction, GetApplications, GetApplicationTranslations, SetApplicationTranslationsAction, SetApplicationsLoadingStatus, SetApplicationTranslationsLoadingStatus
} from './actions';
import {
	getAllApplications, Application, AllApplicationTranslations, getAllApplicationTranslations
} from '../../API/application';
import { ApplicationsActionTypes, ApplicationsAppStateModel } from './types';
import { AppState } from '../';

function* loadApplications(action: GetApplications) {
	const { payload: { accessToken } } = action;

	const { applicationsState } = (yield select((state: AppState) => {
		return { applicationsState: state.applications };
	})) as { applicationsState: ApplicationsAppStateModel; };

	if (applicationsState.applicationsLoadingStatus.loading || applicationsState.applicationsLoadingStatus.loaded) {
		return;
	}

	try {
		yield put(SetApplicationsLoadingStatus({ loading: true, loaded: false, failed: false }));
		let applications: Application[] = yield getAllApplications(accessToken);

		yield put(SetApplicationsAction(applications));
		yield put(SetApplicationsLoadingStatus({ loading: false, loaded: true, failed: false }));
	}
	catch (error) {
		yield put(SetApplicationsLoadingStatus({ loading: false, loaded: false, failed: true }));
	}
}

function* loadAllApplicationTranslations(action: GetApplicationTranslations) {

	const { applicationsState } = (yield select((state: AppState) => {
		return { applicationsState: state.applications };
	})) as { applicationsState: ApplicationsAppStateModel; };

	if (applicationsState.applicationTranslationsLoadingStatus.loading || applicationsState.applicationTranslationsLoadingStatus.loaded) {
		return;
	}

	try {
		yield put(SetApplicationTranslationsLoadingStatus({ loading: true, loaded: false, failed: false }));
		let appTrans: AllApplicationTranslations = yield getAllApplicationTranslations();

		yield put(SetApplicationTranslationsAction(appTrans.application_text));
		yield put(SetApplicationTranslationsLoadingStatus({ loading: false, loaded: true, failed: false }));
	}
	catch (error) {
		yield put(SetApplicationTranslationsLoadingStatus({ loading: false, loaded: false, failed: true }));
	}
}

function* watchLoadApplications() {
	yield takeLatest(ApplicationsActionTypes.GET_APPLICATIONS, loadApplications);
}

function* watchLoadApplicationTranslations() {
	yield takeLatest(ApplicationsActionTypes.GET_APPLICATIONTRANSLATIONS, loadAllApplicationTranslations);
}

export default function* applicationsSagas() {

	yield all([
		fork(watchLoadApplications),
		fork(watchLoadApplicationTranslations),
	]);
}
